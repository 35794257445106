import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnDark.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import parivahan from "images/parivahan-mockup.jpg"
import ayushman from "images/ayushman-bharat-card.jpg";
import aadhar from "images/aadhar-card.png"
import pan from "images/pan-card.png"
import vaccination from "images/vaccination-card.png"
import ration from "images/ration-card.png"
import eshram from "images/e-shram-card.png"
import janaadhar from "images/jan-aadhar.png"
import quickpvc from "images/quick-pvc-all-in-one.png"
import uhid from "images/uhid-card.png"
import neetu_verma from "images/neetu-verma.jpg"
import mohd_shoaib from "images/mohd.-shoaib.jpg"
import suneet from "images/suneet-meena.jpg"
import PopUpForm from "components/forms/PopUpForm";

const HighlightedText = tw.span`text-primary-500`

export default () => {
  useEffect(()=>{
    document.title="BarPrint - Home | Best PVC Printing Software";
  },[]);
  const defaultCards = [
    {
      imageSrc: FastIconImage,
      title: "Speed",
      description: "Our solutions are made with advanced technologies and tailor made algorithms. They're designed to deliver quick results."
    },
    { imageSrc: SupportIconImage, title: "24/7 Support" ,description:"Enjoy round-the-clock assistance with our best-in-class 24/7 support. We're here to help anytime, day or night."},
    { imageSrc: ReliableIconImage, title: "Accuracy", description:"We believe that a penny saved is a penny made. Our team ensure that our solutions deliver accurate results, helping our customers save wastage and money." },
    { imageSrc: CustomizeIconImage, title: "Prductivity",description:"All our sofwares are designed by keeping one thing in mind, which is, maximizing the productivity of our end users." },
    { imageSrc: ShieldIconImage, title: "Secure",description:"Your customer data security is our top priority. We use advanced, robust protocols to ensure your information is protected. Trust us to keep your personal details safe and secure at all times." },
    { imageSrc: SimpleIconImage, title: "Easy",description:"Our platform features a super easy interface designed for effortless navigation. Enjoy a seamless user experience that makes managing your tasks a breeze." }
  ];
  const Products=  [
    {
      postImageSrc:parivahan,
        
      authorImageSrc:"",
        
      title: "Parivahan Software",
      description:
        "Parivahan software streamlines the printing process for driving licenses and vehicle registration certificates. Its user-friendly interface and efficient features save time and enhance accuracy, making document management easier for all users.",
      authorName: "",
      authorProfile: "",
      url: "/parivahan",
      featured: true
    },
    {
      postImageSrc:ayushman,
       
      title: "Ayushman Bharat Software",
      authorName: "",
      url: "/ayushman"
    },
    {
      postImageSrc:aadhar,
       
      title: "Aadhar Card Software",
      authorName: "",
      url: "/aadhar"
    },
    {
      postImageSrc:uhid,
       
      title: "Unique Health Id (ABHA) Software",
      authorName: "",
      url: "/uhid"
    },
    {
      postImageSrc:pan,
       
      title: "Pan Card Software",
      authorName: "",
      url: "/pan"
    },
    {
      postImageSrc:vaccination,
       
      title: "Vaccination Card Software",
      authorName: "",
      url: "/vaccination"
    },
    {
      postImageSrc:eshram,
       
      title: "E-shram Card Software",
      authorName: "",
      url: "/eshram"
    },
    {
      postImageSrc:janaadhar,
       
      title: "Jan-Aadhar Card Software",
      authorName: "",
      url: "/janaadhar"
    },
    {
      postImageSrc:ration,
       
      title: "Ration Card Software",
      authorName: "",
      url: "/ration"
    },
    {
      postImageSrc:quickpvc,
       
      title: "Quick Pvc(All In One) Software",
      authorName: "",
      url: "/quickpvc"
    }];
  const testimonials = [
    {
      stars: 5,
      profileImageSrc:neetu_verma,
        heading: "Amazing User Experience",
      quote:
        "I'm delighted to mention that team BarPrint has one the best support teams. They resolve your queries as quick as they happen. I've happily been using their products and services at all of my 5 CSCs. Kudos to team BarPrint!.",
      customerName: "Neetu Verma",
      customerTitle: "Delhi NCR."
    },
    {
      stars: 4.5,
      profileImageSrc:mohd_shoaib,
        heading: "Love the Experience and Efficient Process!",
      quote:
        "मैं एक डीलर हूँ और मेरे पास India के लगभग हर स्टेट के क्लाइंट्स हैं। इस कंपनी के सॉफ्टवेयर इस्तेमाल में बेहद आसान हैं और यही बात इस कंपनी को खास और इनके प्रोडक्ट्स को customers की पहली choice बनाती है।",
      customerName: "Mohd. Shoaib",
      customerTitle: "Lucknow, Uttar Pradesh"
    },
    {
      stars: 5,
      profileImageSrc:suneet,
       heading: "The customer support is outstanding",
      quote:
        "BarPrint के PVC सॉफ्टवेयर मै अपने 8 Centers पर पिछले 1.5 साल से ज्यादा समय से इस्तेमाल कर रहा हूँ। यह कंपनी दुनिआ की बेस्ट सपोर्ट टीम से लेस है, मैं BarPrint की सर्विसेस से बेहद संतुस्ट एवं खुश हूँ।",
      customerName: "Suneet Meena",
      customerTitle: "Kota, Rajasthan"
    }
  ];
  const [openModal, setOpenModal] = useState(false);
  function onCloseModal() {
    setOpenModal(false);
   
  }
  function onOpenModal(){
      setOpenModal(true)
  }
  return (
    <AnimationRevealPage>
      <Hero onOpenHandle={onOpenModal}/>
      <FeatureStats/>
      <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
        description={"Ready to explore and print like a pro? Start your Barprint journey now!"}
        subheading=""
        cards={defaultCards}
      />
      <Blog
        subheading="Products"
        heading={<>Exciting <HighlightedText>Products</HighlightedText></>}
        posts={Products}
      />
      
      <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
        testimonials={testimonials}
      />
      
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
      
      <GetStarted onOpenHandle={onOpenModal}/>
      <PopUpForm openModal={openModal} onCloseHandle={onCloseModal} page='Home' softwareType='Any'/>
      <Footer />
    </AnimationRevealPage>
  );
}
