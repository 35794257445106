import React,{useEffect, useState} from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithInput.js";
import Faq from "components/faqs/TwoColumnPrimaryBackground.js";
import Feature from "components/features/VerticalWithAlternateImageAndText.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnDark.js";
import ImgDesign from "../images/ayushman-bharat-card-product.png"
import barprint_automation from "../images/barprint-automation.png";
import barprint_easyuse from "../images/barprint-easy-use.png";
import PopUpForm from 'components/forms/PopUpForm';

const Ayushman = () => {
    useEffect(()=>{
        document.title="BarPrint- Ayushmaan Bharat Card PVC Printing Software "
      },[]);
    const [openModal, setOpenModal] = useState(false);
    function onCloseModal() {
      setOpenModal(false);
     
    }
    function onOpenModal(){
        setOpenModal(true)
    }
    const cardst = [
        {
          imageSrc:barprint_automation,
           
          subtitle: "",
          title: "100% Automated",
          description:
            "Ayushman software card printing software is specially designed software for those who wants to get things done automatically without putting much efforts manually. Features include external database connections, multiple printer support, and enhanced security. Its more or less a plug and play solution and it serves to all your PVC printing needs.",
          url: "",
          showlink:false
        },
        {
            imageSrc:barprint_easyuse,
              
            subtitle: "",
            title: "Easy and Simple to Use",
            description:
              "Ayushman card printing software is specially designed card software to cater all class of users ranging from techy to non-tech savvy users. Also, we are offering Improved printing quality in a less sophisticated software as a bonus.",
            url: "",
            showlink:false
          },
    ]   ;
   const faqs = [
        {
          question: "Hardware Requirements:",
          answer:
            "PVC Card Printer: Specialized printers capable of printing on PVC cards (e.g., Zebra, Evolis, Magicard, IDP, Fargo, Matica, Sprint, DataCard, EM2 printers). Pre-Printed and Blank PVC cards suitable for printing. So, software use both ribbons half panel or full panel"
        },
        {
            question:"Software Configuration:",
            answer:"Printer Driver Installation: Ensure that the printer drivers for the PVC card printer are correctly installed on the system. Configure the printing settings within the Parivahan software to align with the PVC card printer specifications."
        },
{
    question:"Advantages of Using PVC Cards",
    answer:" PVC cards are more durable than paper-based documents. Easier to carry in wallets or cardholders."
}
    ]; 
    
  return (
    <AnimationRevealPage>
      <Hero onOpenHandle={onOpenModal} heading={"Ayushman Software "} subheading='for ayushman card' paragraph="The Ayushman software used for ayushman bharat printing can indeed be adapted for printing on PVC cards. Here’s an overview of how it works and the considerations involved in using this software for PVC card printing:" design={ImgDesign}/>
      <Feature heading='Ayushman Software'
      paragraph='Creating a tailor-made PVC printing solution for  ayushman cards involves integrating specialized software and hardware to streamline the printing process. Here’s a detailed guide on setting up such a solution:'
     cards={cardst}
     />
      <Faq heading="Software Specification"
      description='To print Ayushman card on PVC cards, follow these steps:'
      faqs={faqs}/>
      <GetStarted onOpenHandle={onOpenModal}/>
      <PopUpForm openModal={openModal} onCloseHandle={onCloseModal} page='Ayushman' softwareType='Ayushman'/>
      <Footer />
    </AnimationRevealPage>
  )
}

export default Ayushman