
"use client";
import tw from "twin.macro";
import { Button, MegaMenu, Navbar } from 'flowbite-react';
export const NavLink = tw.li`inline-block
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0 m-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;
const NavBar = ({onOpenHandle={}}) => {
  





  return (
    <MegaMenu>
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4 md:space-x-8">
        
        
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link href="/" className='hocus:text-primary-500'>Home</Navbar.Link>
         
          <Navbar.Link>
            <MegaMenu.Dropdown toggle={<>Products</>}>
              <ul className="grid grid-cols-1">
                <div className="space-y-4 p-4">
                  <li>
                    <a href="/parivahan" className="hover:text-primary-600 dark:hover:text-primary-500">
                     Parivahan(DL and RC) software
                    </a>
                  </li>
                  <li>
                    <a href="/aadhar" className="hover:text-primary-600 dark:hover:text-primary-500">
                      Aadhar software
                    </a>
                  </li>
                  <li>
                    <a href="/ayushman" className="hover:text-primary-600 dark:hover:text-primary-500">
                      Ayushman software
                    </a>
                  </li>
                  <li>
                    <a href="/uhid" className="hover:text-primary-600 dark:hover:text-primary-500">
                     UHID (ABHA) software
                    </a>
                  </li>
                  <li>
                    <a href="/pan" className="hover:text-primary-600 dark:hover:text-primary-500">
                     Pan Card software
                    </a>
                  </li>
                  <li>
                    <a href="/vaccination" className="hover:text-primary-600 dark:hover:text-primary-500">
                    Vaccination  software
                    </a>
                  </li>
                  <li>
                    <a href="/eshram" className="hover:text-primary-600 dark:hover:text-primary-500">
                     E-shram  software
                    </a>
                  </li>
                  <li>
                    <a href="/janaadhar" className="hover:text-primary-600 dark:hover:text-primary-500">
                     Jan Aadhar software
                    </a>
                  </li>
                  <li>
                    <a href="/ration" className="hover:text-primary-600 dark:hover:text-primary-500">
                     Ration card software
                    </a>
                  </li>
                  <li>
                    <a href="/quickpvc" className="hover:text-primary-600 dark:hover:text-primary-500">
                    Quick Pvc software
                    </a>
                  </li>
                </div>
              
              </ul>
            </MegaMenu.Dropdown>
          </Navbar.Link>
          <Navbar.Link href="#">Become a Distributor</Navbar.Link>
          <Navbar.Link href="/contact">Contact</Navbar.Link>
          <PrimaryLink style={{marginTop:"-7px"}} onClick={onOpenHandle}>
            Request for Demo
          </PrimaryLink>
        </Navbar.Collapse>
      </div>
    </MegaMenu>
  );
}


export default NavBar;