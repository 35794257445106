import React,{useEffect, useState} from 'react'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { useSearchParams } from 'react-router-dom';
import Hero from "components/hero/SingleColumnWithDownload.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";

import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnDark.js";
import parivahan from "images/parivahan-mockup.jpg"
import ayushman from "images/ayushman-bharat-card.jpg";
import aadhar from "images/aadhar-card.png"
import pan from "images/pan-card.png"
import vaccination from "images/vaccination-card.png"
import ration from "images/ration-card.png"
import eshram from "images/e-shram-card.png"
import janaadhar from "images/jan-aadhar.png"
import quickpvc from "images/quick-pvc-all-in-one.png"
import uhid from "images/uhid-card.png"
import PopUpForm from 'components/forms/PopUpForm';

const HighlightedText = tw.span`text-primary-500`

const Product = () => {
    useEffect(()=>{
        document.title="BarPrint- Aadhar, Ayushmaan, Pan, Jan aadhar, Uhid(ABHA), E shram, Vaccination, covid certificate, Ration, Parivahan, QuickPVC, All in one  Card PVC Printing Software "
      },[]);
    const [searchParams] = useSearchParams();
    const link = searchParams.get('link');
    const status = searchParams.get('status');
    const software = searchParams.get('sw');
    const [openModal, setOpenModal] = useState(false);
    function onCloseModal() {
      setOpenModal(false);
     
    }
    function onOpenModal(){
        setOpenModal(true)
    }
    const Products=  [
        {
          postImageSrc:parivahan,
            
          authorImageSrc:"",
            
          title: "Parivahan Software",
          description:
            "Parivahan software streamlines the printing process for driving licenses and vehicle registration certificates. Its user-friendly interface and efficient features save time and enhance accuracy, making document management easier for all users.",
          authorName: "",
          authorProfile: "",
          url: "/parivahan",
          featured: true
        },
        {
          postImageSrc:ayushman,
           
          title: "Ayushman Bharat Software",
          authorName: "",
          url: "/ayushman"
        },
        {
          postImageSrc:aadhar,
           
          title: "Aadhar Card Software",
          authorName: "",
          url: "/aadhar"
        },
        {
          postImageSrc:uhid,
           
          title: "Unique Health Id (ABHA) Software",
          authorName: "",
          url: "/uhid"
        },
        {
          postImageSrc:pan,
           
          title: "Pan Card Software",
          authorName: "",
          url: "/pan"
        },
        {
          postImageSrc:vaccination,
           
          title: "Vaccination Card Software",
          authorName: "",
          url: "/vaccination"
        },
        {
          postImageSrc:eshram,
           
          title: "E-shram Card Software",
          authorName: "",
          url: "/eshram"
        },
        {
          postImageSrc:janaadhar,
           
          title: "Jan-Aadhar Card Software",
          authorName: "",
          url: "/janaadhar"
        },
        {
          postImageSrc:ration,
           
          title: "Ration Card Software",
          authorName: "",
          url: "/ration"
        },
        {
          postImageSrc:quickpvc,
           
          title: "Quick Pvc(All In One) Software",
          authorName: "",
          url: "/quickpvc"
        }];
  return (
    <AnimationRevealPage>
      <Hero onOpenHandle={onOpenModal}  SoftwareName={software} status={status} downloadLink={link}/>
      <Blog
        subheading="Products"
        heading={<>Exciting <HighlightedText>Products</HighlightedText></>}
        posts={Products}
      />
      <GetStarted onOpenHandle={onOpenModal}/>
      <PopUpForm openModal={openModal} onCloseHandle={onCloseModal} page='Product' softwareType='Any'/>
      <Footer /> 
      </AnimationRevealPage>
  )
}

export default Product