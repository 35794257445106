import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import axios from "axios";



const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-10/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-center`;


const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton ,SoftwareName="Software Name",downloadLink="",status="",onOpenHandle={}}) => {
    
    useEffect(()=>{
        //downloadLink="http://localhost:3000/download/bpps.zip";
       // window.location.href=downloadLink;
        console.log(downloadLink);
//downloadPdf();

    if(downloadLink!="" && downloadLink!=null)
  {
    
    downloadPdf();
  }
},[])
const downloadPdf = async () => {
  
    try {
      const response = await axios.get(
        downloadLink,
        {
          responseType: "blob", 
        }
      );

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data]);

      // Create a temporary URL for the Blob
      const url = window.URL.createObjectURL(pdfBlob);

      // Create a temporary <a> element to trigger the download
      const tempLink = document.createElement("a");
      tempLink.href = url;
      tempLink.setAttribute(
        "download",
        SoftwareName+".zip"
      ); // Set the desired filename for the downloaded file

      // Append the <a> element to the body and click it to trigger the download
      document.body.appendChild(tempLink);
      tempLink.click();

      // Clean up the temporary elements and URL
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

const buttonRoundedCss =   tw`rounded-full`;
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} onOpenHandle={onOpenHandle}/>
      {status=="saved"&&<Container>
        <TwoColumn>
          <LeftColumn>
            {SoftwareName!="Any"?<>
            <Heading>
            Thank You for Downloading <span tw="text-primary-500">{SoftwareName}</span>
            </Heading>
            <Paragraph>
            Thank you for downloading {SoftwareName}! We're thrilled to have you with us and excited for you to explore all the features our software has to offer.

If you did not successfully download the software, please click the link below to try again:


            </Paragraph>
            <PrimaryButton as="a" onClick={downloadPdf} css={buttonRoundedCss}>
                {"Download"}
              </PrimaryButton>
              </>:<>
              <Heading>
              Thank you for contacting us!
            </Heading>
            <Paragraph>
            We appreciate your interest in our products and services. Our team is currently reviewing your message, and we will get back to you as soon as possible. Typically, we respond within 24-48 hours. If your inquiry is urgent, please mention it in your email, and we will prioritize your request.
<br/><br/><br/>
In the meantime, feel free to  explore our latest products on our website.


            </Paragraph>
              </>}
            <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip>
          </LeftColumn>
          
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>}
    </>
  );
};
