import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import axios, { isAxiosError } from "axios";
import { useForm } from "react-hook-form";

import {  toast } from 'react-toastify';
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0 border-primary-100 border-2 p-5 bg-primary-800 rounded-lg`
const Input = tw.input`mt-6 first:mt-0 border-2 p-1 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`
const Select = tw.select`mt-6 first:mt-0 border-2 p-1 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  description = "Contact us today and let's work together to create something truly special. Your satisfaction is our top priority, and we're committed to making every moment with us a memorable one.",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const {
    register,
    handleSubmit,
    formState,
} = useForm ();

const[showForm,setShowForm]=useState(true);

const onSubmitHandler = (form) => {
 
    return new Promise((resolve) => {
   axios.get("https://panel.barprint.in/SaveDownloadDetails.asmx/SaveDetail?Name="+form.name+"&Phone="+form.phone+"&softwareType="+form.softwareType+"&Printer="+form.printer+"&Message="+form.message+"&Page=contact",{
    
   }).then((res)=>{
    if(res.data=="unsaved")
      toast.warning("Data not saved")
    else
   {
    toast.success("Data saved");
   setShowForm(false);
   }
   })  .catch((errors)=>{
    if(isAxiosError(errors)){
      toast.error(errors?.message);
    }
    else
    toast.error(errors?.status);
   })   
        
       
    });
   
    
};
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {!showForm?<><br/><br/><br/>
            <Subheading>Thank you for contacting us!</Subheading>
            <Description>We appreciate your interest in our products and services. Our team is currently reviewing your message, and we will get back to you as soon as possible. Typically, we respond within 24-48 hours. If your inquiry is urgent, please mention it in your email, and we will prioritize your request.</Description>
            </>:
            <Form onSubmit={handleSubmit(onSubmitHandler)}>
              <Input type="phone" placeholder="Your phone no" {...register("phone",{required:"Please enter valid phone no"})}/>
              {formState.errors.phone? (
     <p className="text-red font-xssss" style={{color:"red"}}>{formState.errors.phone.message}</p>
 ) : (
     ""
 )} 
             <Input type="text"  placeholder="Full Name" {...register("name",{required:"Please enter name"})}/>
             {formState.errors.name? (
     <p className="text-red font-xssss" style={{color:"red"}}>{formState.errors.name.message}</p>
 ) : (
     ""
 )}
              
              <Select {...register("softwareType",{required:"Please select software"})}>
              <option value="" selected disabled hidden>Select Software</option>
                <option value={"Parivahan"}>Parivahan (DL and RC)</option>
                <option value={"Ayushman"} >Ayushman bharat</option>
                <option value={"Voter"}>Voter card</option>
                <option value={"Aadhar"}>Aadhar card</option>
                <option value={"Eshram"}>Eshram</option>
                <option value={"Ration"}>Ration card</option>
                <option value={"JanAadhar"}>Jan aadhar</option>
                <option value={"Vaccination"}>Vaccination</option>
                <option value={"Pan"}>Pan card</option>
                <option value={"UHID"}>UHID card(ABHA)</option>
                <option value={"ID"}>Id card</option>
                <option value={"QuickPVC"}>Quick pvc(all in one for paper printer)</option>  
              </Select>
              {formState.errors.softwareType? (
     <p className="text-red font-xssss" style={{color:"red"}}>{formState.errors.softwareType.message}</p>
 ) : (
     ""
 )}
               <Select {...register("printer",{required:"Please select printer"})}>
              <option value="" selected disabled hidden>Select Printer</option>
                <option value={"Evolis"}>Evolis</option>
                <option value={"Magic Card"} >Magic Card</option>
                <option value={"Zebra"}>Zebra</option>
                <option value={"Fargo"}>Fargo</option>
                <option value={"IDP"}>IDP</option>
                <option value={"Data Card"}>Data card</option>
                <option value={"SPrint"}>SPrint</option>
                <option value={"Matica"}>Matica</option>
                <option value={"Epson"}>Epson</option>
                <option value={"Canon"}>Canon</option>
                <option value={"HP"}>HP</option>
                <option value={"Brother"}>Brother</option>
                <option value={"Other"}>Other</option>
              </Select>             
              {formState.errors.printer ? (
     <p className="text-red font-xssss" style={{color:"red"}}>{formState.errors.printer.message}</p>
 ) : (
     ""
 )}
              <Textarea {...register("message")} placeholder="Your Message Here" />
              <SubmitButton disabled={formState.isSubmitting} type="submit">{submitButtonText}</SubmitButton>
            </Form>
}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
